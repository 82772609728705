import React, { useContext } from "react";
import axios from "axios";
import { eventLog } from "../../Shared/helperFunctions";
import { DataContext } from "../../Shared/context";

const CareerAdvisor = (props) => {
  const data = useContext(DataContext);
  const { user: userId, token } = data;
  let eventName = "part3-" + props.title;
  // console.log(eventName);
  return (
    <p>
      <strong>Speak with {props.title} Career Advisor</strong> <br />
      Want to bounce ideas off of someone? Have career questions you need to
      discuss with an experienced professional?{" "}
      <a
        href={props.link}
        target="_blank"
        onClick={(ev) => {
          // ev.preventDefault();
          eventLog(axios, userId, encodeURIComponent(eventName), token, 'Link');
        }}
        rel="noopener noreferrer"
        className="colored-link ml-1"
      >
        Make an appointment with an ASU career advisor!
      </a>
    </p>
  );
};

export default CareerAdvisor;
