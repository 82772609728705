import React, { useContext } from "react";
import axios from "axios";
import { eventLog } from "../../Shared/helperFunctions";
import { DataContext } from "../../Shared/context";

const MentorNetwork = (props) => {
  const data = useContext(DataContext);
  const { user: userId, token } = data;
  let eventName = "part3-" + props.title;
  return (
    <p>
      <strong>{props.title} Mentor Network</strong> <br />
      Connect individually with alumni, industry professionals, and ASU faculty
      who are willing to offer you advice. Or join a group with others that have
      similar professional goals and experience. Ask as many questions as you
      have no matter where you are in the process.{" "}
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(ev) => {
          // ev.preventDefault();
          eventLog(axios, userId, encodeURIComponent(eventName), token, 'Link');
        }}
        className="colored-link ml-1"
      >
        Find your mentor now.
      </a>
    </p>
  );
};

export default MentorNetwork;
