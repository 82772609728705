import React from "react";

const TabContent = (props) => {
  return (
    <div className="checkbox--custom form-group">
      <input
        type="checkbox"
        className="checkbox--custom-input"
        id={props.id}
        name="milestone-3-checkbox"
        checked={props.checked}
        onChange={(e) => props.inputHandler(e, props.title)}
      />
      <label className="checkbox--custom-label" htmlFor={props.htmlFor}>
        {props.children}
      </label>
    </div>
  );
};

export default TabContent;
