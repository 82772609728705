import React, { useRef, useContext } from "react";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { scrollToRef, eventLog } from "../Shared/helperFunctions";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import { DataContext } from "../Shared/context";

const UpToDateResume = (props) => {
  const data = useContext(DataContext);
  const cardRef = useRef(null);
  const {
    resume: value,
    setResume: setValue,
    part4: completed,
    part3: prevCompleted,
    user: userId,
    token,
  } = data;
  // let prevCompleted = props.formStatus.part3.completed;
  // let completed = props.formStatus.part4.completed;
  // console.log(value, "resume");
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=4&values=" +
          `${value}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("save4", JSON.parse(res.data));
        scrollToRef(cardRef);
        data.setPart4(true);
        // setTimeout(() => props.clicked(4), 1000);
        // submitHandler(props, 4, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=4&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("reset4", JSON.parse(res.data));
        data.setPart4(false);
        data.setResume(false);
        // submitHandler(props, 4, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Check_Uptodate_Resume"
      ref={cardRef}
    >
      <div
        className={
          "card-header " +
          (props.show["showPart4"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-4"
        aria-expanded="true"
        onClick={() => props.clicked(4)}
      >
        Do you have an up to date résumé?
      </div>
      <CSSTransition
        in={props.show["showPart4"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-4"
          // className={"collapse " + (props.show["showPart4"] ? "show" : "")}
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            <div className="status--not">
              <div className="checkbox--custom form-group">
                <input
                  type="radio"
                  className="radio--custom-input"
                  id="milestone-4-check-1"
                  name="milestone-4-checkbox"
                  checked={!value}
                  onChange={() => {
                  	setValue(!value);
                    eventLog(axios, userId, "4-upToDateResume-RadioChange", token, "No");
                  }}
                  value="no"
                />
                <label
                  className="radio--custom-label"
                  htmlFor="milestone-4-check-1"
                >
                  <p>
                    <strong>No</strong>{" "}
                    <span
                      className="Check_Uptodate_Resume_No"
                      style={
                        value
                          ? { display: "none" }
                          : { display: " inline-block" }
                      }
                    >
                      <br />
                      Now is a great time to get started! A well-crafted resume
                      will get you that perfect internship, student worker
                      position or first paying job.
                    </span>
                  </p>
                </label>
              </div>

              <div className="checkbox--custom form-group">
                <input
                  type="radio"
                  className="radio--custom-input"
                  id="milestone-4-check-2"
                  name="milestone-4-checkbox"
                  value="yes"
                  checked={value}
                  onChange={() => {
                  	setValue(!value);
                    eventLog(axios, userId, "4-upToDateResume-RadioChange", token, "Yes");
                  }}
                />
                <label
                  className="radio--custom-label"
                  htmlFor="milestone-4-check-2"
                >
                    <strong>Yes I do and it is fantastic.</strong><p>{" "}
                    <span
                      className="Check_Uptodate_Resume_Yes"
                      style={
                        !value
                          ? { display: "none" }
                          : { display: " inline-block" }
                      }
                    >
                      <br /> Awesome job! Remember to keep updating it as you
                      gain experience and skills.
                    </span>
                  </p>
                </label>
              </div>

              <a
                href="https://career.asu.edu/r%C3%A9sum%C3%A9s-and-application-materials"
                target="_blank"
                onClick={(ev) => {
                  // ev.preventDefault();
                  eventLog(axios, userId, "4-Resume", token, 'Link');
                }}
                rel="noopener noreferrer"
                className="btn btn-black btn-rounded mr-3 get_start_resume"
                style={
                  value ? { display: "none" } : { display: " inline-block" }
                }
              >
                Get started on your resume
              </a>

              <button
                type="button"
                disabled={prevCompleted ? !"disabled" : "disabled"}
                className="btn btn-secondary btn-rounded complete_milestone_btn"
                style={
                  !value ? { display: "none" } : { display: " inline-block" }
                }
                onClick={submitData}
              >
                <span className="flaticon-tick-1 icon"></span> Complete
                Milestone
              </button>
            </div>

            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default UpToDateResume;
