import React, { useRef, useContext, useState } from "react";
import { scrollToRef, eventLog } from "../Shared/helperFunctions";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import { DataContext } from "../Shared/context";

const AfterGraduation = (props) => {
  const data = useContext(DataContext);
  const cardRef = useRef(null);
  const {
    afterGraduate: value,
    setAfterGraduate: setValue,
    part8: completed,
    part7: prevCompleted,
    user: userId,
    token,
  } = data;
  const [service, setService] = useState("");
  const [employment, setEmployment] = useState("");
  const [school, setSchool] = useState("");
  // const [graduateReady, setGraduateReady] = useState(false);
  // let prevCompleted = props.formStatus.part7.completed;
  // let completed = props.formStatus.part8.completed;
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=8&values=" +
          `${value}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("save8", JSON.parse(res.data));
        scrollToRef(cardRef);
        data.setPart8(true);
        setTimeout(() => props.clicked(8), 1000);
        // submitHandler(props, 8, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=8&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("reset8", JSON.parse(res.data));
        data.setPart8(false);
        data.setAfterGraduate("");
        // setGraduateReady(false);
        // submitHandler(props, 8, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Steps_After_Graduation"
      ref={cardRef}
    >
      <div
        className={
          "card-header " +
          (props.show["showPart8"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-8"
        aria-expanded="true"
        onClick={() => props.clicked(8)}
      >
        What are you planning to do after graduation?
      </div>
      <CSSTransition
        in={props.show["showPart8"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-8"
          // className={"collapse " + (props.show["showPart8"] ? "show" : "")}
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            <div className="status--not">
              <p>
                Choose the option below that most closely matches your intended
                plans after graduation.{" "}
              </p>
              <div className="radio--custom form-group--double">
                <input
                  type="radio"
                  className="radio--custom-input"
                  id="milestone-8-check-1"
                  name="graduation_next_step"
                  value="full-time-employment"
                  checked={value === "full-time-employment"}
                  onChange={(e) => {
                  	setValue(e.target.value);

                    eventLog(axios, userId, "8-afterGraduation-RadioChange", token, "FullTimeEmployment");
                  }}
                />
                <label
                  className="radio--custom-label"
                  htmlFor="milestone-8-check-1"
                >
                  <p>
                    <strong>Full Time Employment</strong>
                  </p>
                </label>
                <div
                  className="dynamic-content dynamic-content--Employment"
                  style={
                    value === "full-time-employment"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="form-group">
                    <input
                      type="radio"
                      className="radio--custom-input"
                      id="milestone-8-check-1-1"
                      onChange={(e) => {
                      	setEmployment("yes");
                    	eventLog(axios, userId, "8-afterGraduation-FullTimeEmployment-RadioChange", token, "Yes");
                      }}
                      checked={employment === "yes"}
                    />
                    <label
                      className="radio--custom-label"
                      htmlFor="milestone-8-check-1-1"
                    >
                      <p>
                        <strong>Yes, I've found a job!</strong>
                        <br />
                        Congratulations! Let us know where your next adventure
                        will take you. Log in to{" "}
                        <a
                          href="https://asu.joinhandshake.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(ev) => {
                            // ev.preventDefault();
                            eventLog(axios, userId, "8-found-a-job", token, "Link");
                          }}
                          className="colored-link ml-1"
                        >
                          Handshake
                        </a>{" "}
                        to complete your first destination survey. The survey
                        will be available one month prior to graduation. Don't
                        forget to connect with ASU career services in the
                        future. You have access to free career services for life
                        as a Sun Devil alumni.
                      </p>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="radio"
                      className="radio--custom-input"
                      id="milestone-8-check-1-2"
                      onChange={(e) => {
                      	setEmployment("no");
                    	eventLog(axios, userId, "8-afterGraduation-FullTimeEmployment-RadioChange", token, "No");
                      }}
                      checked={employment === "no"}
                    />
                    <label
                      className="radio--custom-label"
                      htmlFor="milestone-8-check-1-2"
                    >
                      <p>
                        <strong>No, I am still looking</strong>
                        <br />
                        Use the resources below to kick your job search
                        preparation and application process into high gear.
                      </p>
                    </label>
                  </div>
                  {employment === "no" ? (
                    <>
                      <div className="form-group">
                        <p>
                          <strong>Interview Resources</strong>
                          <br />
                          If you are applying for positions then get started now
                          practicing your interviewing skills. Record your
                          responses and watch them back to gain helpful insight
                          or submit to a career professional for helpful
                          feedback.{" "}
                          <a
                            href="https://career.asu.edu/interviewing"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              eventLog(axios, userId, "8-Interview-Resources", token, "Link");
                            }}
                            className="colored-link ml-1"
                          >
                            Learn more.
                          </a>
                        </p>
                      </div>

                      <div className="form-group form-group--double">
                        <p>
                          <strong>Search for Jobs</strong>
                          <br /> ASU has partnered with 1000's of diverse
                          organizations to bring a variety of opportunities
                          right to you.{" "}
                          <a
                            href="https://asu.joinhandshake.com/postings"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              eventLog(axios, userId, "8-Search-for-Jobs", token, "Link");
                            }}
                            className="colored-link ml-1"
                          >
                            Check out who is waiting for you to apply.
                          </a>
                        </p>
                      </div>
                    </>
                  ) : null}

                  <a
                    href="/"
                    className="btn btn-secondary btn-rounded complete_milestone_btn"
                    disabled={
                      prevCompleted && employment === "yes"
                        ? !"disabled"
                        : "disabled"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      submitData();
                    }}
                  >
                    <span className="flaticon-tick-1 icon"></span>Complete
                    Career Milestone Journey
                  </a>
                  <hr />
                </div>
              </div>

              <div className="radio--custom form-group--double">
                <input
                  type="radio"
                  className="radio--custom-input"
                  id="milestone-8-check-2"
                  name="graduation_next_step"
                  value="graduate-professional-school"
                  onChange={(e) => {
                  	setValue(e.target.value);
                    eventLog(axios, userId, "8-afterGraduation-RadioChange", token, "Graduate_ProfessionalSchool");
                  }}
                  checked={value === "graduate-professional-school"}
                />
                <label
                  className="radio--custom-label"
                  htmlFor="milestone-8-check-2"
                >
                  <p>
                    <strong>Graduate/Professional School</strong>
                  </p>
                </label>
                <div
                  className="dynamic-content dynamic-content--Graduate"
                  style={
                    value === "graduate-professional-school"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="form-group">
                    <input
                      type="radio"
                      className="radio--custom-input"
                      id="milestone-10-check-1-1"
                      // name="graduation_next_step"
                      value="national-or-international-service-yes"
                      onChange={(e) => {
                      	setSchool("yes");
                    	eventLog(axios, userId, "8-afterGraduation-Graduate_ProfessionalSchool-RadioChange", token, "Yes");
                      }}
                      checked={school === "yes"}
                    />
                    <label
                      className="radio--custom-label"
                      htmlFor="milestone-10-check-1-1"
                    >
                      <p>
                        <strong>
                          Yes, I am ready to continue my education!
                        </strong>
                        <br /> We wish you all the best on your next educational
                        journey!Be sure to share where your next adventure will
                        take you. <br /> Log in to{" "}
                        <a
                          href="https://asu.joinhandshake.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(ev) => {
                            // ev.preventDefault();
                            eventLog(axios, userId, "8-ready-to-continue-my-education", token, "Link");
                          }}
                          className="colored-link ml-1"
                        >
                          Handshake
                        </a>{" "}
                        to complete your first destination survey. The survey
                        will be available one month prior to graduation.
                      </p>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="radio"
                      className="radio--custom-input"
                      id="milestone-10-check-1-2"
                      // name="graduation_next_step"
                      value="national-or-international-service-yes"
                      onChange={(e) => {
                      	setSchool("no");
                    	eventLog(axios, userId, "8-afterGraduation-Graduate_ProfessionalSchool-RadioChange", token, "No");
                      }}
                      checked={school === "no"}
                    />
                    <label
                      className="radio--custom-label"
                      htmlFor="milestone-10-check-1-2"
                    >
                      <p>
                        <strong>No, I need help preparing to apply.</strong>
                        <br /> ASU has lots of resources to help you apply for
                        acceptance to your next program of study. Check out the
                        resources below to get started.
                      </p>
                    </label>
                  </div>
                  {school === "no" ? (
                    <>
                      <div className="form-group">
                        <p>
                          <strong>Test Preparation</strong>
                          <br /> Take advantage of free online test prep
                          materials. Tests options include GRE, GMAT, LSAT and
                          MCAT.{" "}
                          <a
                            href="https://career.asu.edu/grad-school-and-test-prep"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              eventLog(axios, userId, "8-Test-Preparation", token, "Link");
                            }}
                            className="colored-link ml-1"
                          >
                            Learn more.
                          </a>
                        </p>
                      </div>

                      <div className="form-group">
                        <p>
                          <strong>Preprofessional Advising </strong>
                          <br /> Considering professional school? ASU offers
                          preparation advice and advising for various pathways.
                          Learn more about{" "}
                          <a
                            href="https://prehealth.asu.edu/advising"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              eventLog(axios, userId, "8-Preprofessional-Advising-Health", token, "Link");
                            }}
                            className="colored-link ml-1"
                          >
                            pre-health advising
                          </a>{" "}
                          or{" "}
                          <a
                            href="https://prelaw.asu.edu/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              eventLog(axios, userId, "8-Preprofessional-Advising-Law", token, "Link");
                            }}
                            className="colored-link ml-1"
                          >
                            pre-law advising.
                          </a>
                        </p>
                      </div>

                      <div className="form-group">
                        <p>
                          <strong>ASU Graduate Admissions </strong>
                          <br /> When considering your graduate school options,
                          give us a look. ASU offers more than 400 graduate
                          degree options that rank among the best in the nation.
                          Why leave a good thing?{" "}
                          <a
                            href="https://admission.asu.edu/graduate"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              eventLog(axios, userId, "8-ASU-Graduate-Admissions", token, "Link");
                            }}
                            className="colored-link ml-1"
                          >
                            Learn more.
                          </a>
                          <br />
                        </p>
                      </div>
                      <div className="form-group">
                        <p>
                          <strong> Interested in scholarships?</strong>
                          <br /> Choose from one of the many options available
                          to you.{" "}
                          <a
                            href="https://scholarships.asu.edu/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              eventLog(axios, userId, "8-ASU-Graduate-Admissions", token, "Link");
                            }}
                            className="colored-link ml-1"
                          >
                            Learn more.
                          </a>
                        </p>
                      </div>
                    </>
                  ) : null}

                  <a
                    href="/"
                    className="btn btn-secondary btn-rounded complete_milestone_btn"
                    disabled={
                      prevCompleted && school === "yes"
                        ? !"disabled"
                        : "disabled"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      submitData();
                    }}
                  >
                    <span className="flaticon-tick-1 icon"></span>Complete
                    Career Milestone Journey
                  </a>
                  {/* </div> */}
                  <hr />
                </div>
              </div>

              <div className="radio--custom form-group--double">
                <input
                  type="radio"
                  className="radio--custom-input"
                  id="milestone-8-check-3"
                  name="graduation_next_step"
                  value="national-or-international-service"
                  onChange={(e) => {
                  	setValue(e.target.value);
                    eventLog(axios, userId, "8-afterGraduation-RadioChange", token, "National_InternationalService");
                  }}
                  checked={value === "national-or-international-service"}
                />
                <label
                  className="radio--custom-label"
                  htmlFor="milestone-8-check-3"
                >
                  <p>
                    <strong>National or International service</strong>
                  </p>
                </label>
                <div
                  className="dynamic-content dynamic-content--service"
                  style={
                    value === "national-or-international-service"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="form-group">
                    <input
                      type="radio"
                      className="radio--custom-input"
                      id="milestone-8-check-3-1"
                      // name="graduation_next_step"
                      value="national-or-international-service-yes"
                      onChange={(e) => {
                      	setService("yes");
                    	eventLog(axios, userId, "8-afterGraduation-National_InternationalService-RadioChange", token, "Yes");
                      }}
                      checked={service === "yes"}
                    />
                    <label
                      className="radio--custom-label"
                      htmlFor="milestone-8-check-3-1"
                    >
                      <p>
                        <strong>Yes, I am excited to serve!</strong>
                        <br />
                        ASU applauds your choice to make an impact in the world!
                        Be sure to share the details of your service with us.
                        Log in to {" "}
                        <a
                          href="https://asu.joinhandshake.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(ev) => {
                            // ev.preventDefault();
                            eventLog(axios, userId, "8-exited-to-serve", token, "Link");
                          }}
                          className="colored-link ml-1"
                        >
                          Handshake
                        </a>{" "}
                        to complete your first destination survey. The survey
                        will be available one month prior to graduation.
                        <br /> Don't forget to connect with ASU career services
                        in the future. You have access to free career services
                        for life as a Sun Devil alumni.
                      </p>
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      type="radio"
                      className="radio--custom-input"
                      id="milestone-8-check-3-2"
                      // name="graduation_next_step"
                      value="national-or-international-service-no"
                      onChange={(e) => {
                      	setService("no");
                    	eventLog(axios, userId, "8-afterGraduation-National_InternationalService-RadioChange", token, "No");
                      }}
                      checked={service === "no"}
                    />
                    <label
                      className="radio--custom-label"
                      htmlFor="milestone-8-check-3-2"
                    >
                      <p>
                        <strong>
                          No, I am still trying to find the best option for me.
                        </strong>
                        <br /> Explore ways that you can create positive social
                        change in our local and global communities through
                        high-impact, paid service opportunities. ASU has strong
                        partnerships with organizations like AmeriCorps, Peace
                        Corps and Teach For America. Learn more about these
                        life-changing opportunities to apply your education in a
                        unique way.{" "}
                        <a
                          href="https://eoss.asu.edu/makeyourimpact"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(ev) => {
                            // ev.preventDefault();
                            eventLog(axios, userId, "8-exited-to-serve", token, "Link");
                          }}
                          className="colored-link ml-1"
                        >
                          Learn more
                        </a>{" "}
                      </p>
                    </label>
                  </div>

                  <a
                    href="/"
                    className="btn btn-secondary btn-rounded complete_milestone_btn"
                    disabled={
                      prevCompleted && service === "yes"
                        ? !"disabled"
                        : "disabled"
                    }
                    // style={
                    //   !service
                    //     ? { display: "none" }
                    //     : { display: " inline-block" }
                    // }
                    onClick={(e) => {
                      e.preventDefault();
                      submitData();
                    }}
                  >
                    <span className="flaticon-tick-1 icon"></span>Complete
                    Career Milestone Journey
                  </a>
                  <hr />
                </div>
              </div>

              <div className="no-checkbox form-group">
                <p>
                  <strong>I’m not sure</strong> <br />
                  Not sure about your next step? Don’t worry, we have your back.
                  Schedule a time to{" "}
                  <a
                    href="https://asu.joinhandshake.com/appointments"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(ev) => {
                      // ev.preventDefault();
                      eventLog(axios, userId, "8-not-sure", token, "Link");
                    }}
                    className="colored-link"
                  >
                    connect with a career advisor.
                  </a>
                </p>
              </div>
            </div>

            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default AfterGraduation;
