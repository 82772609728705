import React, { useRef, useContext } from "react";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { scrollToRef, eventLog } from "../Shared/helperFunctions";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import { DataContext } from "../Shared/context";

const ProfessionalNetwork = (props) => {
  const data = useContext(DataContext);
  const cardRef = useRef(null);
  // let prevCompleted = props.formStatus.part6.completed;
  // let completed = props.formStatus.part7.completed;
  const {
    proNet,
    setProNet,
    part7: completed,
    part6: prevCompleted,
    user: userId,
    token,
  } = data;
  const inputHandler = (e, title) => {
	eventLog(axios, userId, title, token, e.target.checked ? "Checked" : "Unchecked");
    if (proNet.includes(e.target.id)) {
      let updateproNet = [];
      updateproNet = proNet.filter((element) => {
        return e.target.id !== element;
      });
      setProNet(updateproNet);
    } else {
      setProNet(proNet.concat(e.target.id));
    }
  };
  // console.log(proNet, "Pro network");
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=7&values=" +
          `${proNet}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("save7", JSON.parse(res.data));
        scrollToRef(cardRef);
        data.setPart7(true);
        // setTimeout(() => props.clicked(7), 1000);
        // submitHandler(props, 7, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=7&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("reset7", JSON.parse(res.data));
        data.setPart7(false);
        data.setProNet([]);
        // submitHandler(props, 7, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Built_Professional_Network"
      ref={cardRef}
    >
      <div
        className={
          "card-header " +
          (props.show["showPart7"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-7"
        aria-expanded="true"
        onClick={() => props.clicked(7)}
      >
        Have you built your professional network?
      </div>
      <CSSTransition
        in={props.show["showPart7"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-7"
          // className={"collapse " + (props.show["showPart7"] ? "show" : "")}
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            {completed ? null : (
              <div className="status--not">
                <p>
                  Building a network of strong connections is an important part
                  of your professional success and may just find you your next
                  job or internship. Take advantage of one of these great
                  opportunities in order to complete this milestone.
                </p>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-7-check-1"
                    name="milestone-7-checkbox"
                    onChange={(e)=>inputHandler(e, "7-professionalNetwork-LinkedIn-CheckboxChange")}
                    checked={proNet.includes("milestone-7-check-1")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-7-check-1"
                  >
                    <p>
                      <strong>LinkedIn</strong> <br />
                      Hundreds of thousands of connections are just waiting for
                      you on LinkedIn.{" "}
                      <a
                        href="https://www.linkedin.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "7-LinkedIn", token, "Link");
                        }}
                        className="colored-link"
                      >
                        Connect with someone working in your career or company
                        of interest.
                      </a>{" "}
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-7-check-2"
                    name="milestone-7-checkbox"
                    onChange={(e)=>inputHandler(e, "7-professionalNetwork-ASUMentorNetwork-CheckboxChange")}
                    checked={proNet.includes("milestone-7-check-2")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-7-check-2"
                  >
                    <p>
                      <strong>ASU Mentor Network</strong> <br />A mentor will
                      keep you grounded in your professional journey and provide
                      direction as you need it. Who wouldn't want a champion in
                      your corner?{" "}
                      <a
                        href="https://mentorship.asu.edu"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "7-ASU-Mentor-Network", token, "Link");
                        }}
                        className="colored-link"
                      >
                        Connect with ASU alumni and industry professionals
                        looking to support you.
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-7-check-3"
                    name="milestone-7-checkbox"
                    onChange={(e)=>inputHandler(e, "7-professionalNetwork-ProfessionalOrganizations-CheckboxChange")}
                    checked={proNet.includes("milestone-7-check-3")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-7-check-3"
                  >
                    <p>
                      <strong>Professional Organizations</strong> <br />
                      Didn't join while exploring your career? Don't hesitate
                      now! Find the one that suits you best, get involved and
                      stay active!{" "}
                      <a
                        href="https://career.asu.edu/little-fish-big-splash-joining-professional-organization"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(
                            axios,
                            userId,
                            "7-Professional-Organizations",
                            token,
                            "Link"
                          );
                        }}
                        className="colored-link"
                      >
                        Help me find a professional organization.
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-7-check-4"
                    name="milestone-7-checkbox"
                    onChange={(e)=>inputHandler(e, "7-professionalNetwork-MaintainingProfessionalReferences-CheckboxChange")}
                    checked={proNet.includes("milestone-7-check-4")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-7-check-4"
                  >
                    <p>
                      <strong>Maintaining Professional References</strong>{" "}
                      <br /> Are you able to name three current references who
                      would speak knowledgably and favorably about your skills
                      and experience?{" "}
                      <a
                        href="https://career.asu.edu/who-makes-good-reference"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(
                            axios,
                            userId,
                            "7-Maintaining-Professional-References",
                            token,
                            "Link"
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="colored-link"
                      >
                        Learn how to choose a good reference.
                      </a>
                    </p>
                  </label>
                </div>

                <button
                  type="button"
                  disabled={
                    prevCompleted && proNet.length !== 0
                      ? !"disabled"
                      : "disabled"
                  }
                  onClick={submitData}
                  className="btn btn-secondary btn-rounded complete_milestone_btn"
                >
                  <span className="flaticon-tick-1 icon"></span> Complete
                  Milestone
                </button>
              </div>
            )}

            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ProfessionalNetwork;
