import React, { useRef, useContext } from "react";
import axios from "axios";

import { CSSTransition } from "react-transition-group";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { scrollToRef, eventLog } from "../Shared/helperFunctions";
import { DataContext } from "../Shared/context";

const HandShakeProfile = (props) => {
  // console.log("handshake.js");
  const data = useContext(DataContext);
  const cardRef = useRef(null);
  const {
    handshakePro: value,
    setHandshakePro: setValue,
    part1: completed,
    user: userId,
    token,
  } = data;
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=1&values=" +
          `${value}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log(process.env.);
        scrollToRef(cardRef);
        data.setPart1(true);
        // setTimeout(() => props.clicked(1), 1000);
        // submitHandler(props, 1, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=1&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("reset1", JSON.parse(res.data));
        data.setPart1(false);
        data.setHandshakePro(false);
        // submitHandler(props, 1, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(process.env.REACT_APP_SUBMIT_URL);
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Handshake_Profile_Card"
      ref={cardRef}
    >
      <div
        className={
          "card-header " +
          (props.show["showPart1"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-1"
        aria-expanded="true"
        onClick={() => props.clicked(1)}
      >
        Have you activated your Handshake profile?
      </div>
      <CSSTransition
        in={props.show["showPart1"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-1"
          // className="collapse show"
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            {completed ? null : (
              <div className="status--not">
                <div className="checkbox--custom form-group">
                  <input
                    type="radio"
                    className="radio--custom-input"
                    id="career-profile-1"
                    name="career-profile"
                    checked={!value}
                    value="no"
                    onChange={() => {
                    	setValue(!value);
                    	eventLog(axios, userId, "1-HandshakeProfile-RadioChange", token, "No");
                    }}
                  />
                  <label
                    className="radio--custom-label"
                    htmlFor="career-profile-1"
                  >
                    <p className="mb-0">
                      <strong>No</strong>
                      <br />
                      <span
                        className="Handshake_Profile_Card_No"
                        style={
                          value
                            ? { display: "none" }
                            : { display: " inline-block" }
                        }
                      >
                        <br />
                        Want to stay connected with career support and
                        resources? <br /> Complete your Handshake profile in
                        just minutes.
                      </span>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="radio"
                    className="radio--custom-input"
                    id="career-profile-2"
                    name="career-profile"
                    value="yes"
                    checked={value}
                    onChange={() => {
                    	setValue(!value);
                    	eventLog(axios, userId, "1-HandshakeProfile-RadioChange", token, "Yes");
                    }}
                  />
                  <label
                    className="radio--custom-label"
                    htmlFor="career-profile-2"
                  >
                    <p>
                      <strong>Yes</strong>
                      <br />
                      <span
                        className="Handshake_Profile_Card_Yes"
                        style={
                          !value
                            ? { display: "none" }
                            : { display: " inline-block" }
                        }
                      >
                        Congrats on completing a milestone!
                      </span>
                    </p>
                  </label>
                </div>

                <a
                  href="https://asu.joinhandshake.com/"
                  target="_blank"
                  onClick={(ev) => {
                    // ev.preventDefault();
                    eventLog(axios, userId, "1-HandshakeProfile-ButtonClick", token, "Complete your Handshake profile");
                  }}
                  rel="noopener noreferrer"
                  className="btn btn-black btn-rounded mr-2 complete_handshake_btn"
                  style={
                    value ? { display: "none" } : { display: " inline-block" }
                  }
                >
                  Complete your Handshake profile
                </a>

                <button
                  type="button"
                  className="btn btn-secondary btn-rounded complete_milestone_btn"
                  style={
                    !value ? { display: "none" } : { display: " inline-block" }
                  }
                  onClick={submitData}
                >
                  <span className="flaticon-tick-1 icon"></span> Complete
                  Milestone
                </button>
              </div>
            )}

            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default HandShakeProfile;
