import React, { useContext } from "react";
import axios from "axios";
import { eventLog } from "../../Shared/helperFunctions";
import { DataContext } from "../../Shared/context";

const Organizations = (props) => {
  const data = useContext(DataContext);
  const { user: userId, token } = data;
  let eventName = "part3-" + props.title;
  return (
    <p>
      <strong>{props.title} Student & Professional Organizations</strong> <br />{" "}
      Joining an organization now will open the door for you to learn more about
      your selected career and begin building your professional network.{" "}
      <a
        href={props.link}
        target="_blank"
        onClick={(ev) => {
          // ev.preventDefault();
          eventLog(axios, userId, encodeURIComponent(eventName), token, 'Link');
        }}
        rel="noopener noreferrer"
        className="colored-link ml-1"
      >
        Check these organizations out!
      </a>
    </p>
  );
};

export default Organizations;
